



























import Vue from "vue";
import Footer from "../components/footer/Footer.vue"

export default Vue.extend({
  name: "HealthSafety",
  components: {
    Footer
  },
});
